import { KEY_CACHE_USER_DATA } from '@/context/authContext'
import { TypeDataUser } from '@/context/authContext/typeAuth'

export function getDataUserLocalStorage(): TypeDataUser {
  const LocalStorageStore = localStorage.getItem(KEY_CACHE_USER_DATA)

  if (LocalStorageStore) {
    return JSON.parse(LocalStorageStore)
  }

  return {
    userId: '',
    name: '',
    email: '',
    image: ''
  }
}
