import { IFieldsDeliveries, IGETProjectSections } from '@/@types/createDelivery'
import { PropsDataStepAddProjectExternalKey } from '@/app/(privado)/projeto/processos-e-fluxos-ludicos/adicionar-entrega/_components/StepAddProjectExternalKey'
import { PropsDataStepAddProjectLocationAndStep } from '@/app/(privado)/projeto/processos-e-fluxos-ludicos/adicionar-entrega/_components/StepAddProjectLocation'
import { PropsDataStepAddProjectStructureAndBudget } from '@/app/(privado)/projeto/processos-e-fluxos-ludicos/adicionar-entrega/_components/StepAddProjectStructureAndBudget'

import { TypeFieldAndValue } from '@/@types/delivery'

export const isEmptyObject = (object: object) => {
  if (object === null || object === undefined) return true
  return Object.keys(object).length === 0
}

export const fakeApiRequest = async () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const success = true
      if (success) {
        resolve({ data: 'Dados da API aqui' })
      } else {
        reject(new Error('Erro na chamada da API'))
      }
    }, 3000)
  })
}

type PropsFormatedForFiedsPost = {
  fieldsAssignments: IFieldsDeliveries[]
  selectableFieldsAssignments: IFieldsDeliveries[]
  apiFieldsAssignments: IFieldsDeliveries[]
}

type InputFormatedForFiedsPost =
  | PropsDataStepAddProjectLocationAndStep
  | PropsDataStepAddProjectStructureAndBudget
  | PropsDataStepAddProjectExternalKey
  | TypeFieldAndValue[]

export const formatedForFiedsPost = (
  arrayValue: IGETProjectSections,
  data: InputFormatedForFiedsPost
): PropsFormatedForFiedsPost => {
  const resultFields: IFieldsDeliveries[] = []
  const resultSelectable: IFieldsDeliveries[] = []
  const resultApiFieldsAssignments: IFieldsDeliveries[] = []

  for (const key in data) {
    const validKey = key as keyof InputFormatedForFiedsPost

    const fieldValue = data[validKey]

    if (fieldValue !== undefined) {
      const { title, value, type } = fieldValue

      const correspondingObject = arrayValue.fields.find((item) => {
        return item.title === title
      })

      if (correspondingObject && value !== '' && value !== undefined) {
        const formatedArrayInput = Array.isArray(value) ? value : [value]

        const coordFormated =
          (validKey === 'latitude' || validKey === 'longitude') &&
          typeof formatedArrayInput === 'string'
            ? [convertCoordinatesToDD(formatedArrayInput)]
            : formatedArrayInput

        const formattedDelivery: IFieldsDeliveries = {
          fieldId: correspondingObject.id,
          fieldValues: coordFormated
        }

        if (type === 'field') resultFields.push(formattedDelivery)
        else if (type === 'selectable') resultSelectable.push(formattedDelivery)
        else resultApiFieldsAssignments.push(formattedDelivery)
      }
    }
  }

  return {
    fieldsAssignments: resultFields,
    selectableFieldsAssignments: resultSelectable,
    apiFieldsAssignments: resultApiFieldsAssignments
  }
}

// File[] To FileList
export function fileListFrom(files: File[]): FileList {
  const b = new ClipboardEvent('').clipboardData || new DataTransfer()
  for (const file of files) b.items.add(file)
  return b.files
}

// Base64 to File
export function dataURLtoFile(dataURL: string, fileName: string): File {
  const arr: string[] = dataURL.split(',')
  const mime: string | null = arr[0].match(/:(.*?);/)?.[1] || ''
  const bstr: string = atob(arr[1])
  let n: number = bstr.length
  const u8arr: Uint8Array = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], fileName, { type: mime })
}
