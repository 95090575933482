import coineData from '@/utils/mocks/coine.json'

import { Document, Process } from '@/@types/listProcessInfo'

export function wrapTextInStrongTags(text: string): string | JSX.Element {
  const parts = text.split(/(".*?")/g).map((part, index) => {
    return part.startsWith('"') ? <strong key={index}>{part}</strong> : part
  })

  return <>{parts}</>
}

type KeyValue<T> = {
  [key: string]: T
}

type Component = KeyValue<{
  imageSrc: string
  altText: string
  title: string
  description: string
  route: string
  documents: KeyValue<Document>
  processes: KeyValue<Process>
}>

interface JsonData {
  units: Component
  programs: Component
}

const dataMap: KeyValue<JsonData> = {
  coine: coineData
}

export function listAll(coordination: string) {
  const data: JsonData = dataMap[coordination]

  return { units: data?.units, programs: data?.programs }
}

export function findProcessesAndDocumentsByComponent(
  component: string,
  coordination: string
): { documents: KeyValue<Document>; processes: KeyValue<Process> } {
  const data: JsonData = dataMap[coordination]

  return (data?.units?.[component] || data?.programs?.[component]) ?? []
}

export function findProcessById(
  coordination: string,
  component: string,
  processId: string
) {
  const data: JsonData = dataMap[coordination]

  return (
    (data?.units?.[component]?.processes[processId] ||
      data?.programs?.[component]?.processes[processId]) ??
    []
  )
}
