'use client'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from 'react'
import { useRouter, useSearchParams } from 'next/navigation'
import Cookies from 'js-cookie'
import axios from 'axios'

import { isEmptyObject } from '@/utils/functions'

import { TypeUserInfoDataAuth } from './typeAuth'

interface AuthContextProps {
  handleLogin: () => Promise<void>
  handleLogout: () => void
}

interface AuthContextProviderProps {
  children: React.ReactNode
}

export const KEY_CACHE_TOKEN = 'authToken'
export const KEY_CACHE_USER_ROLES = 'userRolesAcess'
export const KEY_CACHE_USER_DATA = 'userDataAcess'

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps)

function AuthContextProvider({ children }: AuthContextProviderProps) {
  const { push } = useRouter()

  const handleLogin = useCallback(async () => {
    push(
      `${process.env.NEXT_PUBLIC_LOGIN_WEB_APP}?app-key=${process.env.NEXT_PUBLIC_APP_KEY}`
    )
  }, [push])

  const handleLogout = useCallback(() => {
    Cookies.remove(KEY_CACHE_TOKEN)
    Cookies.remove(KEY_CACHE_USER_ROLES)
    localStorage.removeItem(KEY_CACHE_USER_DATA)
  }, [])

  const valuesProvider = useMemo(() => {
    return {
      handleLogin,
      handleLogout
    }
  }, [handleLogin, handleLogout])

  return (
    <AuthContext.Provider value={valuesProvider}>
      {children}
    </AuthContext.Provider>
  )
}

function useAuthentication() {
  const context = useContext(AuthContext)

  if (isEmptyObject(context)) {
    throw new Error(
      'useAuthentication must be used within a AuthContextProvider'
    )
  }

  return context
}

function useFetchAndVerifyAcessoCidadao() {
  const searchParams = useSearchParams()
  const { replace } = useRouter()

  useEffect(() => {
    const fetchAndVerifyApiAcessoCidadao = async () => {
      const tokenUrl = searchParams.get('token')
      const tokenCache = Cookies.get(KEY_CACHE_TOKEN)

      if (tokenCache)
        return replace(
          '/projeto/processos-e-fluxos-ludicos/listar/coordenadorias'
        )

      if (tokenUrl) {
        try {
          const responseAcessoCidadao = await axios.get<TypeUserInfoDataAuth>(
            `${process.env.NEXT_PUBLIC_LOGIN_SERVER}/auth/me`,
            {
              headers: {
                Authorization: tokenUrl,
                'Accept-Client': `${process.env.NEXT_PUBLIC_APP_KEY}`
              }
            }
          )

          if (responseAcessoCidadao.data) {
            Cookies.set(KEY_CACHE_TOKEN, tokenUrl)
            Cookies.set(
              KEY_CACHE_USER_ROLES,
              JSON.stringify(responseAcessoCidadao.data.roles)
            )
            const { userId, name, email, image } =
              responseAcessoCidadao.data.client
            localStorage.setItem(
              KEY_CACHE_USER_DATA,
              JSON.stringify({ userId, name, email, image: image || '' })
            )

            replace('/projeto/processos-e-fluxos-ludicos/listar/coordenadorias')
            return
          }

          throw new Error('O usuário não possui autorizacao.')
        } catch (error) {
          console.error('Erro ao processar solicitação à API:', error)

          Cookies.remove(KEY_CACHE_TOKEN)
          Cookies.remove(KEY_CACHE_USER_ROLES)
          localStorage.removeItem(KEY_CACHE_USER_DATA)
        }
      }

      replace('/entrar')
    }

    fetchAndVerifyApiAcessoCidadao()
  }, [replace, searchParams])
}

export {
  AuthContextProvider,
  useAuthentication,
  useFetchAndVerifyAcessoCidadao
}
